import gql from 'graphql-tag'

export const PAIRS_VOLUME_QUERY = gql`
  query PairsVolume($limit: Int!, $pairIds: [ID!]!, $blockNumber: Int!) {
    pairVolumes: pairs(first: $limit, where: { id_in: $pairIds }, block: { number: $blockNumber }) {
      id
      volumeToken0
      volumeToken1
    }
  }
`

export const TOKEN_BY_ADDRESS = gql`
  query Token($id: ID!) {
    token(id: $id) {
      id
      name
      symbol
      derivedETH
      derivedUSD
    }
  }
`

export const TOP_PAIRS = gql`
  fragment TokenInfo on Token {
    id
    name
    symbol
    derivedETH
    derivedUSD
  }

  query TopPairs($limit: Int!, $excludeTokenIds: [String!]!) {
    pairs(
      first: $limit
      orderBy: reserveUSD
      orderDirection: desc
      where: { token0_not_in: $excludeTokenIds, token1_not_in: $excludeTokenIds }
    ) {
      id
      token0 {
        ...TokenInfo
      }
      token1 {
        ...TokenInfo
      }
      reserve0
      reserve1
      volumeToken0
      volumeToken1
      reserveETH
      reserveUSD
    }
  }
`

export const DAY_DATA = gql`
  query SectaDayData($startTime: Int!, $skip: Int!) {
    pancakeDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      id
      date
      dailyVolumeUSD
      totalLiquidityUSD
    }
  }
`

export const PROTOCOL_DATA_BY_BLOCK = gql`
  query SectaProtocolDataByBlock($block: Block_height) {
    pancakeFactories(block: $block, first: 1) {
      totalTransactions
      totalVolumeUSD
      totalLiquidityUSD
    }
  }
`

export const PROTOCOL_DATA_LAST = gql`
  query SectaProtocolDataLast {
    pancakeFactories(first: 1) {
      totalTransactions
      totalVolumeUSD
      totalLiquidityUSD
    }
  }
`

export const TOP_TOKENS_LAST = gql`
  query SectaTopTokensLast2 {
    tokens(first: 100, orderBy: tradeVolumeUSD, orderDirection: desc) {
      id
      symbol
      name
      decimals
      derivedETH
      derivedUSD
      tradeVolume
      tradeVolumeUSD
      totalLiquidity
      totalTransactions
    }
  }
`

export const TOP_TOKENS_BY_BLOCK = gql`
  query SectaTopTokensByBlock2($block: Block_height) {
    tokens(block: $block, first: 100, orderBy: tradeVolumeUSD, orderDirection: desc) {
      id
      symbol
      name
      decimals
      derivedETH
      derivedUSD
      tradeVolume
      tradeVolumeUSD
      totalLiquidity
      totalTransactions
    }
  }
`

export const TOP_POOLS_LAST = gql`
  query SectaTopPoolsLast {
    pairs(orderBy: reserveUSD, orderDirection: desc) {
      id
      token0 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token0Price
      token1Price
      volumeUSD
      volumeToken0
      volumeToken1
      totalTransactions
      reserve0
      reserve1
      reserveUSD
    }
  }
`

export const TOP_POOLS_BY_BLOCK = gql`
  query SectaTopPoolsByBlock($block: Block_height) {
    pairs(block: $block, orderBy: reserveUSD, orderDirection: desc) {
      id
      token0 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token0Price
      token1Price
      volumeUSD
      volumeToken0
      volumeToken1
      totalTransactions
      reserve0
      reserve1
      reserveUSD
    }
  }
`

export const LAST_TRANSACTIONS = gql`
  query SectaLastTranscations {
    transactions(first: 500, orderBy: timestamp, orderDirection: desc) {
      id
      timestamp
      mints {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        sender
        to
        amount0
        amount1
        amountUSD
      }
      swaps {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        from
        amount0In
        amount0Out
        amount1In
        amount1Out
        amountUSD
      }
      burns {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        sender
        to
        amount0
        amount1
        amountUSD
      }
    }
  }
`
