import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client/core'
import fetch from 'cross-fetch'

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const client = new ApolloClient({
  link: new HttpLink({
    fetch,
    uri: 'https://api.studio.thegraph.com/query/66239/secta-linea-exchange-v2/version/latest',
  }),
  cache: new InMemoryCache(),
  defaultOptions,
})
