import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client/core'
import fetch from 'cross-fetch'

export const blockClient = new ApolloClient({
  link: new HttpLink({
    fetch,
    uri: 'https://api.studio.thegraph.com/query/66239/secta-linea-blocks/version/latest',
  }),
  cache: new InMemoryCache(),
})
