import gql from 'graphql-tag'

export const PAIRS_VOLUME_QUERY = gql`
  query PairsVolume($limit: Int!, $pairIds: [ID!]!, $blockNumber: Int!) {
    pairVolumes: pools(first: $limit, where: { id_in: $pairIds }, block: { number: $blockNumber }) {
      id
      volumeToken0
      volumeToken1
    }
  }
`

export const TOKEN_BY_ADDRESS = gql`
  query Token($id: ID!) {
    token(id: $id) {
      id
      name
      symbol
      derivedETH
      derivedUSD
    }
  }
`

export const TOP_PAIRS = gql`
  fragment TokenInfo on Token {
    id
    name
    symbol
    derivedETH
    derivedUSD
  }

  query TopPairs($limit: Int!, $excludeTokenIds: [String!]!) {
    pools(
      first: $limit
      orderBy: totalValueLockedUSD
      orderDirection: desc
      where: { token0_not_in: $excludeTokenIds, token1_not_in: $excludeTokenIds }
    ) {
      id
      token0 {
        ...TokenInfo
      }
      token1 {
        ...TokenInfo
      }
      totalValueLockedToken0
      totalValueLockedToken1
      volumeToken0
      volumeToken1
      totalValueLockedETH
      totalValueLockedUSD
    }
  }
`
export const DAY_DATA = gql`
  query SectaDayData($startTime: Int!, $skip: Int!) {
    pancakeDayDatas(first: 1000, skip: $skip, where: { date_gt: $startTime }, orderBy: date, orderDirection: asc) {
      id
      date
      volumeUSD
      tvlUSD
      feesUSD
    }
  }
`

export const PROTOCOL_DATA_BY_BLOCK = gql`
  query SectaProtocolDataByBlock($block: Block_height) {
    factories(block: $block, first: 1) {
      txCount
      totalVolumeUSD
      totalProtocolFeesUSD
      totalValueLockedUSD
      totalFeesUSD
    }
  }
`

export const PROTOCOL_DATA_LAST = gql`
  query SectaProtocolDataLast {
    factories(first: 1) {
      txCount
      totalVolumeUSD
      totalProtocolFeesUSD
      totalValueLockedUSD
      totalFeesUSD
    }
  }
`

export const TOP_TOKENS_LAST = gql`
  query SectaTopTokensLast3 {
    tokens(first: 100, orderBy: totalValueLockedUSD, orderDirection: desc) {
      id
      symbol
      name
      decimals
      derivedETH
      derivedUSD
      volume
      volumeUSD
      txCount
      totalValueLocked
      feesUSD
      totalValueLockedUSD
    }
    bundles(where: { id: "1" }) {
      ethPriceUSD
    }
  }
`

export const TOP_TOKENS_BY_BLOCK = gql`
  query SectaTopTokensByBlock3($block: Block_height) {
    tokens(block: $block, first: 100, orderBy: totalValueLockedUSD, orderDirection: desc) {
      id
      symbol
      name
      decimals
      derivedETH
      derivedUSD
      volume
      volumeUSD
      txCount
      totalValueLocked
      feesUSD
      totalValueLockedUSD
    }
    bundles(where: { id: "1" }) {
      ethPriceUSD
    }
  }
`

export const TOP_POOLS_BY_BLOCK = gql`
  query SectaTopPoolsByBlock($block: Block_height) {
    pools(block: $block, orderBy: totalValueLockedUSD, orderDirection: desc) {
      id
      feeTier
      liquidity
      sqrtPrice
      tick
      token0 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token0Price
      token1Price
      volumeUSD
      volumeToken0
      volumeToken1
      txCount
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedUSD
      feesUSD
      protocolFeesUSD
    }
    bundles(where: { id: "1" }) {
      ethPriceUSD
    }
  }
`

export const TOP_POOLS_LAST = gql`
  query SectaTopPoolsLast {
    pools(orderBy: totalValueLockedUSD, orderDirection: desc) {
      id
      feeTier
      liquidity
      sqrtPrice
      tick
      token0 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token1 {
        id
        symbol
        name
        decimals
        derivedETH
      }
      token0Price
      token1Price
      volumeUSD
      volumeToken0
      volumeToken1
      txCount
      totalValueLockedToken0
      totalValueLockedToken1
      totalValueLockedUSD
      feesUSD
      protocolFeesUSD
    }
    bundles(where: { id: "1" }) {
      ethPriceUSD
    }
  }
`
export const LAST_TRANSACTIONS = gql`
  query SectaLastTranscations {
    transactions(first: 500, orderBy: timestamp, orderDirection: desc) {
      id
      timestamp
      mints {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        owner
        sender
        origin
        amount0
        amount1
        amountUSD
      }
      swaps {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        origin
        amount0
        amount1
        amountUSD
      }
      burns {
        token0 {
          id
          symbol
        }
        token1 {
          id
          symbol
        }
        owner
        origin
        amount0
        amount1
        amountUSD
      }
    }
  }
`
